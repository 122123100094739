import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';

const ImageMap = ({
  data,
  minimap,
  showContext,
  contextOpenFunction,
  editPosition,
  editStartingPoint,
  showViewer,
  onClickItem,
  showDataEditPanel,
}) => {
  const [minimapSize, setMinimapSize] = useState({ width: 0, height: 0 });
  const [values, setValues] = useState();
  const [positions, setPositions] = useState(null);
  const [initialPositions, setInitialPositions] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [draggingItem, setDraggingItem] = useState(null);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isDraggingMap, setIsDraggingMap] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const [scaleFactor, setScaleFactor] = useState(1);
  const [expand, setExpand] = useState(false);
  const initialClickPosition = useRef(null);
  const scaleRef = useRef(scale);

  const handleMouseDown = (e, item) => {
    e.preventDefault();
    if (e.button === 2) {
      return;
    }

    const clickedCell = e.target.closest('.cell');
    if (clickedCell && item) {
      setDraggingItem(item);
      setOffset({
        x: e.clientX - positions[item.id].left,
        y: e.clientY - positions[item.id].top,
      });
    } else {
      initialClickPosition.current = { x: e.clientX, y: e.clientY };
      setIsDraggingMap(true);
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseMove = (e) => {
    if (draggingItem) {
      contextOpenFunction(e);
      setPositions((prevPositions) => ({
        ...prevPositions,
        [draggingItem.id]: {
          top: e.clientY - offset.y,
          left: e.clientX - offset.x,
        },
      }));
    } else if (isDraggingMap) {
      contextOpenFunction(e);
      const dx = e.clientX - dragStart.x;
      const dy = e.clientY - dragStart.y;
      setDragOffset({
        x: dragOffset.x + dx,
        y: dragOffset.y + dy,
      });
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };
  const handleMouseUp = (e) => {
    e.preventDefault();
    if (e.button === 2) {
      return;
    }
    if (draggingItem) {
      const { top: initialTop, left: initialLeft } =
        initialPositions[draggingItem.id];
      const { top: newTop, left: newLeft } = positions[draggingItem.id];

      const initialDBCoords = convertPositionToDBCoords(
        initialLeft,
        initialTop,
        values
      );
      const newDBCoords = convertPositionToDBCoords(newLeft, newTop, values);

      setPopupInfo({
        id: draggingItem.id,
        view_distance: Math.sqrt(draggingItem.view_distance),
        initial: {
          x: initialLeft,
          y: initialTop,
          dbX: initialDBCoords.x,
          dbY: initialDBCoords.y,
        },
        current: {
          x: newLeft,
          y: newTop,
          dbX: newDBCoords.x,
          dbY: newDBCoords.y,
        },
      });
      if (showDataEditPanel) {
        onClickItem(e, draggingItem.id);
      }
    } else {
      if (initialClickPosition.current) {
        const distanceX = Math.abs(e.clientX - initialClickPosition.current.x);
        const distanceY = Math.abs(e.clientY - initialClickPosition.current.y);

        if (distanceX < 1 && distanceY < 1) {
          const minimapElement = document.getElementById('minimap');
          const minimapRect = minimapElement.getBoundingClientRect();
          const mouseX = e.clientX;
          const mouseY = e.clientY;
          const minimapX = mouseX - minimapRect.left;
          const minimapY = mouseY - minimapRect.top;
          const clickedPosition = convertPositionToDBCoords(
            minimapX,
            minimapY,
            values
          );

          contextOpenFunction(e, null, clickedPosition.x, clickedPosition.y);
        }
      }
    }
    setDraggingItem(null);
    setIsDraggingMap(false);
  };
  const handleResetPosition = () => {
    const { top: initialTop, left: initialLeft } =
      initialPositions[popupInfo.id];

    setPositions((prevPositions) => ({
      ...prevPositions,
      [popupInfo.id]: {
        top: initialTop,
        left: initialLeft,
      },
    }));

    setPopupInfo();

    setDraggingItem(null);
    setIsDraggingMap(false);
  };
  const handleWheel = (e) => {
    const zoomChange = e.deltaY > 0 ? -0.1 : 0.1;
    const newZoom = Math.min(Math.max(scale + zoomChange, 0.3), 10);
    setScale(newZoom);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const newPositions = calculatePosition(scale, 50, expand);
      setPositions(newPositions);
      if (
        !initialPositions ||
        Object.keys(initialPositions).length === 0 ||
        !Object.keys(newPositions).includes(data[0].id)
      ) {
        setInitialPositions(newPositions);
      } else {
        const updatedInitialPositions = Object.keys(initialPositions).reduce(
          (acc, id) => {
            acc[id] = {
              top: initialPositions[id].top * (scale / scaleRef.current),
              left: initialPositions[id].left * (scale / scaleRef.current),
            };
            return acc;
          },
          {}
        );
        setInitialPositions(updatedInitialPositions);
      }
      scaleRef.current = scale;
    }
    if (minimap) {
      const img = new Image();
      img.src = minimap.img_original;
      img.crossOrigin = 'anonymous'; // CORS 문제 방지 (필요할 경우)
      img.onload = () =>
        setMinimapSize({ width: img.naturalWidth, height: img.naturalHeight });
    }
  }, [data, minimap, scale, expand]);

  const calculatePosition = (zoomFactor = 1, cellSize = 50, expand = false) => {
    if (!data || data.length === 0) return {};

    let minX = -minimapSize.width / 200,
      maxX = minimapSize.width / 200,
      minY = -minimapSize.height / 200,
      maxY = minimapSize.height / 200;

    let rangeX = maxX - minX;
    let rangeY = maxY - minY;
    if (rangeX < 3) rangeX = 3;
    if (rangeY < 3) rangeY = 3;

    const scaleFactorValue =
      (Math.min(1000 / rangeX, 1000 / rangeY) * zoomFactor) / cellSize;
    setScaleFactor(scaleFactorValue);

    setValues({ minX, minY, maxX, maxY });

    return data.reduce((acc, item) => {
      acc[item.id] = {
        top: (maxY - item.y_img) * scaleFactorValue * cellSize,
        left: (item.x_img - minX) * scaleFactorValue * cellSize,
      };
      return acc;
    }, {});
  };

  function convertPositionToDBCoords(pixelX, pixelY, values, cellSize = 50) {
    const { minX, minY, maxY } = values;

    const dbX = (pixelX / (cellSize * scaleFactor) + minX).toFixed(3);
    const dbY = (maxY - pixelY / (cellSize * scaleFactor)).toFixed(3);

    return { x: dbX, y: dbY };
  }

  const handleExpandMinimap = () => {
    setExpand(!expand);
  };

  return (
    <GridWrapper>
      {positions && (
        <div
          style={{
            width: 550,
            height: 550,
            overflow: 'hidden',
            position: 'relative',
            border: '1px solid #ccc',
            backgroundColor: 'white',
          }}
          onWheel={handleWheel}
          onMouseMove={(e) => {
            handleMouseMove(e);
          }}
          onMouseUp={(e) => {
            handleMouseUp(e);
          }}
          onMouseLeave={(e) => {
            if (showContext) {
              return;
            }
            handleMouseUp(e);
          }}
        >
          <div
            id="minimap"
            style={{
              width: (minimapSize.width / 100) * 50 * scaleFactor,
              height: (minimapSize.height / 100) * 50 * scaleFactor,
              backgroundImage: `url(${minimap.img_original})`, // 배경 이미지 설정
              backgroundSize: 'cover', // 배경을 div에 맞게 조절
              backgroundPosition: 'center', // 중앙 정렬
              backgroundRepeat: 'no-repeat', // 반복 방지
              border: '1px solid',
              transition: 'transform 0.1s ease',
              cursor: draggingItem || isDraggingMap ? 'grabbing' : 'grab',
              position: 'relative',
              top: dragOffset.y,
              left: dragOffset.x,
            }}
            onMouseDown={(e) => handleMouseDown(e)}
          >
            {popupInfo && (
              <div
                key={'view_distance'}
                className="distance"
                style={{
                  position: 'absolute',
                  top:
                    positions[popupInfo.id]?.top +
                      25 -
                      50 * scaleFactor * popupInfo.view_distance || 0,
                  left:
                    positions[popupInfo.id]?.left +
                      25 -
                      50 * scaleFactor * popupInfo.view_distance || 0,
                  width: 50 * scaleFactor * popupInfo.view_distance * 2,
                  height: 50 * scaleFactor * popupInfo.view_distance * 2,
                  backgroundColor: 'rgba(0, 0, 0, 0.2)', // 올바른 배경색
                  borderRadius: '50%', // 원형으로 설정
                  border: '2px dashed rgba(0, 0, 0, 0.5)', // 테두리 설정
                }}
              />
            )}
            {data?.map((item) => (
              <div
                key={item.id}
                data-id={item.id}
                className="cell"
                style={{
                  position: 'absolute',
                  top: positions[item.id]?.top - 15 || 0, // 셀 높이의 절반만큼 이동
                  left: positions[item.id]?.left - 15 || 0, // 셀 너비의 절반만큼 이동
                  width: 30,
                  height: 30,
                  backgroundImage: `url(${item.img_thumbnail})`,
                  backgroundSize: 'cover',
                  borderRadius: 10,
                  border: item.is_starting ? '4px solid #000000' : 'none',
                }}
                onMouseDown={(e) => handleMouseDown(e, item)}
                onContextMenu={(e) => {
                  contextOpenFunction(e, item);
                }}
              />
            ))}
          </div>
          {popupInfo && (
            <div
              style={{
                position: 'absolute',
                top: Math.min(
                  Math.max(positions[popupInfo.id]?.top + dragOffset.y + 50, 0),
                  550 - 250
                ),
                left: Math.min(
                  Math.max(
                    positions[popupInfo.id]?.left + dragOffset.x + 50,
                    0
                  ),
                  550 - 250
                ),
                backgroundColor: '#fff',
                border: '1px solid #ccc',
                padding: '16px',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                width: '250px',
                fontFamily: 'Arial, sans-serif',
                color: '#333',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <button
                  style={{
                    backgroundColor: '#7B7B7B',
                    border: 'none',
                    color: 'white',
                    borderRadius: '4px',
                    padding: '4px 8px',
                    fontSize: '12px',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => onClickItem(e, popupInfo.id)}
                >
                  정보패널
                </button>
                <button
                  style={{
                    backgroundColor: '#007BFF',
                    border: 'none',
                    color: 'white',
                    borderRadius: '4px',
                    padding: '4px 8px',
                    fontSize: '12px',
                    cursor: 'pointer',
                  }}
                  onClick={() => showViewer(popupInfo.id)}
                >
                  공간뷰어
                </button>
                <button
                  style={{
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                  }}
                  onClick={() => {
                    handleResetPosition();
                  }}
                >
                  X
                </button>
              </div>

              <h3
                style={{
                  margin: '0',
                  marginBottom: '5px',
                  fontSize: '16px',
                }}
              >
                id: {popupInfo.id}
              </h3>
              <div style={{ marginBottom: '3px' }}>
                <strong>초기 DB 좌표:</strong> ({popupInfo.initial.dbX},{' '}
                {popupInfo.initial.dbY})
              </div>
              <div style={{ marginBottom: '3px' }}>
                <strong>현재 DB 좌표:</strong> ({popupInfo.current.dbX},{' '}
                {popupInfo.current.dbY})
              </div>

              {/* 우측으로 몰아넣은 리셋/적용/드로어 버튼 */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '10px',
                }}
              >
                <div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editStartingPoint(popupInfo.id);
                    }}
                    style={{
                      marginRight: '5px',
                      backgroundColor: '#f1f1f1',
                      border: '1px solid #ccc',
                      padding: '6px 12px',
                      borderRadius: '4px',
                      fontSize: '12px',
                      cursor: 'pointer',
                    }}
                  >
                    시작포인트 지정
                  </button>
                  <button
                    onClick={async (e) => {
                      await editPosition(e, [
                        {
                          id: popupInfo.id,
                          x_img: popupInfo.current.dbX,
                          y_img: popupInfo.current.dbY,
                        },
                      ]);
                      setInitialPositions();
                      setPopupInfo();
                    }}
                    style={{
                      backgroundColor: '#4CAF50',
                      border: '1px solid #388E3C',
                      padding: '6px 12px',
                      borderRadius: '4px',
                      fontSize: '12px',
                      color: '#fff',
                      cursor: 'pointer',
                    }}
                  >
                    수정
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </GridWrapper>
  );
};

const LinkButton = styled.button`
  color: ${(props) => (props.color ? props.color : palette.mainOrange)};
  background-color: ${palette.white};
  border: 1px solid
    ${(props) => (props.color ? props.color : palette.mainOrange)};
  &:hover {
    background-color: ${(props) =>
      props.color ? props.color : palette.mainOrangeHover};
    color: ${palette.white};
  }
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 12px;
`;

const GridWrapper = styled.div`
  margin: 0;
  display: flex;
  overflow: hidden;
`;

export default ImageMap;
